import * as React from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"
import ColorModeToggle from './ColorModeToggle'

// styles

const maxWidth = 1100

const Content = styled.main`
  font-family: -apple-system, Roboto, sans-serif, serif;
  padding: 12px;
  width: 100%;
  max-width: ${maxWidth}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Nav = styled.nav`
  width: 100%;
  max-width: ${maxWidth}px;
  display: flex;
  margin-top: 8px;
  ul {
    margin-top: 8px;
    display: flex;
    list-style-type: none;
    li {
      margin: 0 1rem;
    }
  }
`

const PushRight = styled.div`
  margin-left: auto;
  padding-right: 1rem;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Layout = ({ pageTitle, children }) => {
  return (
    <Wrapper>
      <title>{pageTitle}</title>
      <Nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/work">Work</Link></li>
          <li><Link to="/blog">Blog</Link></li>
        </ul>
        <PushRight><ColorModeToggle /></PushRight>
      </Nav>
      <Content>
        {children}
      </Content>
    </Wrapper>
  )
}
export default Layout
